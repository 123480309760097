import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paper, Typography } from '@mui/material';
import logo from '../Assets/logo.jpg';
import { Instagram, LinkedIn, X } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Footer() {
    const { t } = useTranslation();
    const isRTL = i18n.language === 'ar';
  return (
    <div style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
    <Box id='contact' sx={{ flexGrow: 1, backgroundColor: '#fbfbfb', color: '#454461', padding: { xs: '2rem', md: '4rem' } }}>
      <Grid container spacing={2} columns={12} sx={{ textAlign: { xs: 'center', md: 'center' } }}>
        <Grid item xs={12} sm={6} md={3} sx={{ order: { xs: 1, md: 4 ,sm:4} }}>
{/*         <Link style={{ color: '#454461' ,cursor:'pointer',textDecoration:'none'}} to="/" smooth={true} duration={500}>
 */}          <img src={logo} style={{ width: '150px', height: '90px', marginTop: '1rem' }} alt="Logo" />{/* </Link> */}
          <Typography sx={{ color: '#708482', fontSize: { xs: '0.8rem', md: '0.8rem' }, lineHeight: '1.5', fontFamily: isRTL?'Readex Pro': "Lato", fontWeight: '400', marginBottom: '1rem' }}>
           {t("EXPAND YOUR RECRUITMENT HORIZONS")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ order: { xs: 2, md: 1 ,sm:1} }}>
          <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", marginTop: '1rem', marginBottom: '1rem', fontWeight: 'bold', fontSize: { xs: '1.2rem', md: '1.5rem' } }} variant='h5'>{t("Location")}</Typography>
          <Typography sx={{ fontFamily: 'lato', fontSize: { xs: '0.9rem', md: '1rem' } }}>
            CBD, Kenyatta Avenue, Avenue House Plaza, Second Floor <br />
            Nairobi, Kenya
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ order: { xs: 3, md: 2,sm:2 } }}>
        <Link style={{ color: '#454461' ,cursor:'pointer',textDecoration:'none'}} to='tel:+254 795 347833'>
          <Typography sx={{ fontFamily: isRTL?'Readex Pro': "Lato", marginTop: '1rem', marginBottom: '1rem', fontWeight: 'bold', fontSize: { xs: '1.2rem', md: '1.5rem' } }} variant='h5'>{t("Contact us")}</Typography>
          <Typography sx={{ fontFamily: 'lato', fontSize: { xs: '0.9rem', md: '1rem' } }}>
            Kenya: +254 795 347833  
           
          </Typography></Link>
          <Link style={{ color: '#454461' ,cursor:'pointer',textDecoration:'none'}} to='tel:+966540008700'>
          <Typography sx={{ fontFamily: 'lato', fontSize: { xs: '0.9rem', md: '1rem' } }}>
           
            Saudi Arabia: +966 540008700 
           
          </Typography></Link>
          <Link style={{ color: '#454461' ,cursor:'pointer',textDecoration:'none'}} to='mailto:hello@mabcareer.com'>
          <Typography sx={{fontFamily: isRTL?'Readex Pro': "Lato", fontSize: { xs: '0.9rem', md: '1rem' } }}>
           
          Mbarak@mabcareer.com
          </Typography></Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ order: { xs: 4, md: 3 ,sm:3} }}>
          <Typography sx={{fontFamily: isRTL?'Readex Pro': "Lato", marginTop: '1rem', marginBottom: '1rem', fontWeight: 'bold', fontSize: { xs: '1.2rem', md: '1.5rem' } }} variant='h5'>{t("Social Media")}</Typography>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'center' }, gap: '1rem', fontSize: { xs: '1.5rem', md: '2rem' } }}>
          <Link style={{ cursor:'pointer',textDecoration:'none'}} to='https://linkedin.com/in/mabcareer-link-47140621a'>

            <LinkedIn sx={{ color: '#f89767' }} /></Link>
            <Link style={{ cursor:'pointer',textDecoration:'none'}} to='https://www.instagram.com/m.a.bcareer?igsh=N3pob250d2k1ZWIy'>

            <Instagram sx={{ color: '#f89767' }} /></Link>
            <Link style={{ cursor:'pointer',textDecoration:'none'}} to='x.com'>

            <X sx={{ color: '#f89767' }} /></Link>
          </Box>
        </Grid>
      </Grid>
    </Box></div>
  );
}
